import { useContext } from 'react';
import Layout from '../components/Layout'
import { Link } from 'react-router-dom';
import ContextStates from '../context/ContextStates';
import { motion } from 'framer-motion';
import { areaAnimateLR, areaAnimateS, areaAnimateRL, areaAnimateDU } from '../components/Animaciones';
import Slider from '../components/Slider';
import cmms from '../assets/viewequipo1.png'
import ecommerce from '../assets/ecommerce.jpg'
import pos from '../assets/index_pos.png'
import paginas from '../assets/paginasweb.png'
import Clientes from '../components/Clientes';

const Home = () => {
	const { lang } = useContext(ContextStates);


	return (
		<Layout
			page={"Index"}
		>
			<main className='w-full mb-10'>
				<motion.h2
					initial={"offscreen"}
					whileInView={"onscreen"}
					viewport={{ once: true, amount: 0.1 }}
					variants={areaAnimateS}
					className='text-center font-sans text-5xl text-black mt-10'
				>{lang === "EN" ? 'Transformamos ideas en soluciones tecnológicas' : 'We turn ideas into technological solutions'}</motion.h2>
				<Slider />
				<div
					className="w-10/12 mx-auto mt-10 pt-5"
				>
					<div className='w-11/12 mx-auto mt-10 p-3 md:p-20'>
						<motion.h4
							className='text-black font-sans text-4xl text-center'
							initial={"offscreen"}
							whileInView={"onscreen"}
							viewport={{ once: true, amount: 0.1 }}
							variants={areaAnimateS}
							whileHover={{
								//scale: 1.1,
								//transition: { duration: 1 },
							}}
						>{lang === "EN" ? '¿Por qué elegir Mirx Software?' : 'Why Mirx Software?'}</motion.h4>
						<motion.p
							className='text-black mt-10 font-sans font-extralight text-xl text-center'
							initial={"offscreen"}
							whileInView={"onscreen"}
							viewport={{ once: true, amount: 0.1 }}
							variants={areaAnimateS}
						>{lang === "EN" ? 'Porque ofrecemos una amplia gama de soluciones de software personalizadas y eficientes para satisfacer necesidades específicas. La empresa cuenta con un equipo altamente capacitado de expertos en tecnología de la información que trabajan incansablemente para brindar un excelente servicio al cliente y una experiencia de usuario óptima. Además, Mirx se esfuerza por mantenerse a la vanguardia de las últimas tendencias y tecnologías en el mercado para garantizar que sus soluciones sean innovadoras y eficaces. En resumen, los clientes pueden esperar una solución de software de alta calidad y un valor excepcional al elegir Mirx.' :
							'Because we offer a wide range of customized and efficient software solutions to meet specific needs. The company has a highly skilled team of information technology experts who work tirelessly to provide excellent customer service and optimal user experience. Furthermore, Mirx strives to stay ahead of the latest market trends and technologies to ensure its solutions are innovative and effective. In summary, customers can expect high-quality software solutions and exceptional value when choosing Mirx.'}</motion.p>
					</div>
					<motion.h4
						className='text-black font-sans mt-10 text-4xl text-center'
						initial={"offscreen"}
						whileInView={"onscreen"}
						viewport={{ once: true, amount: 0.1 }}
						variants={areaAnimateS}
						whileHover={{
							//scale: 1.1,
							//transition: { duration: 1 },
						}}
					>{lang === "EN" ? 'Nuestro Software' : 'Our Software'}</motion.h4>
					<div className='w-full md:w-11/12 mx-auto mt-10 md:mt-20 px-2 md:px-20 md:flex'>
						<div className='w-full md:w-3/4 pr-5 grid content-center'>
							<motion.p
								className='text-black font-sans font-semibold text-3xl'
								initial={"offscreen"}
								whileInView={"onscreen"}
								viewport={{ once: true, amount: 0.1 }}
								variants={areaAnimateLR}
							>
								CMMS
							</motion.p>
							<motion.p
								className='mt-5 text-black font-sans font-extralight text-xl'
								initial={"offscreen"}
								whileInView={"onscreen"}
								viewport={{ once: true, amount: 0.1 }}
								variants={areaAnimateLR}
							>
								{lang === "EN" ? 'Nuestro software CMMS ofrece una solución completa para la gestión y mantenimiento de sus activos. Con más de 5 años de experiencia, optimiza sus operaciones de mantenimiento y ofrece una plataforma centralizada para el seguimiento y gestión de tareas y órdenes de trabajo. ¡Invierte en nuestro software CMMS hoy mismo!' :
									'Our CMMS software offers a complete solution for managing and maintaining your assets. With over 5 years of experience, it optimizes your maintenance operations and provides a centralized platform for tracking and managing tasks and work orders. Invest in our CMMS software today!'}
							</motion.p>
						</div>
						<motion.div
							className="w-full md:w-1/2 mt-10 md:mt-0"
							initial={"offscreen"}
							whileInView={"onscreen"}
							viewport={{ once: true, amount: 0.1 }}
							variants={areaAnimateRL}
							whileHover={{
								scale: 1.1,
								opacity: 0.8,
								transition: { duration: 0.5 },
							}}
						>
							<Link to={'/softcmms'} >
								<img className='' src={cmms} />
							</Link>
						</motion.div>
					</div>
					<div className='w-full md:w-11/12 mx-auto mt-10 md:mt-20 px-2 md:px-20 md:flex'>
						<motion.div
							className="w-full md:w-1/2 mt-10 md:mt-0"
							initial={"offscreen"}
							whileInView={"onscreen"}
							viewport={{ once: true, amount: 0.1 }}
							variants={areaAnimateLR}
							whileHover={{
								scale: 1.1,
								opacity: 0.8,
								transition: { duration: 0.5 },
							}}
						>
							<img className='' src={ecommerce} />
						</motion.div>
						<div className='w-full md:w-3/4 md:pl-10 grid content-center'>
							<motion.p
								className='text-black font-sans font-semibold text-3xl text-right'
								initial={"offscreen"}
								whileInView={"onscreen"}
								viewport={{ once: true, amount: 0.1 }}
								variants={areaAnimateRL}
							>
								E-Commerce
							</motion.p>
							<motion.p
								className='mt-5 text-black font-sans font-extralight text-xl text-right'
								initial={"offscreen"}
								whileInView={"onscreen"}
								viewport={{ once: true, amount: 0.1 }}
								variants={areaAnimateRL}
							>
								{lang === "EN" ? '¡Transforma tu negocio con nuestro software e-commerce de última generación! Con nuestro software, puedes crear y administrar tu tienda en línea con facilidad, ofreciendo a tus clientes una experiencia de compra en línea fluida y personalizada. Ofrecemos una amplia gama de características, desde la gestión de inventario hasta el seguimiento del envío, para que puedas centrarte en lo que realmente importa: vender. Además, nuestro software está optimizado para móviles para que tus clientes puedan hacer compras desde cualquier lugar, en cualquier momento. ¡Empieza a maximizar tus ventas y aumentar tu alcance con nuestro software e-commerce innovador!.' :
									'Transform your business with our cutting-edge e-commerce software! With our software, you can easily create and manage your online store, offering your customers a smooth and personalized online shopping experience. We offer a wide range of features, from inventory management to shipping tracking, so you can focus on what really matters: selling. Plus, our software is mobile optimized so your customers can shop from anywhere, anytime. Start maximizing your sales and increasing your reach with our innovative e-commerce software!'}
							</motion.p>
						</div>
					</div>
					<div className='w-full md:w-11/12 mx-auto mt-10 md:mt-20 px-2 md:px-20 md:flex'>
						<div className='w-full md:w-3/4 pr-5 grid content-center'>
							<motion.p
								className='text-black font-sans font-semibold text-3xl'
								initial={"offscreen"}
								whileInView={"onscreen"}
								viewport={{ once: true, amount: 0.1 }}
								variants={areaAnimateLR}
							>
								{lang === "EN" ? 'Sistema de Punto de Venta (POS)':'Point of Sale (POS) System'}
							</motion.p>
							<motion.p
								className='mt-5 text-black font-sans font-extralight text-xl'
								initial={"offscreen"}
								whileInView={"onscreen"}
								viewport={{ once: true, amount: 0.1 }}
								variants={areaAnimateLR}
							>
								{lang === "EN" ? 'Mirx Software POS está diseñado para satisfacer las diversas necesidades de los negocios, ofreciendo un sistema de punto de venta (POS) robusto, intuitivo y personalizable que garantiza eficiencia operativa y una satisfacción excepcional del cliente.' :
									'Mirx Software POS is designed to meet the diverse needs of businesses, offering a robust, intuitive, and customizable point of sale (POS) system that ensures operational efficiency and exceptional customer satisfaction.'}
							</motion.p>
						</div>
						<motion.div
							className="w-full md:w-1/2 mt-10 md:mt-0"
							initial={"offscreen"}
							whileInView={"onscreen"}
							viewport={{ once: true, amount: 0.1 }}
							variants={areaAnimateRL}
							whileHover={{
								scale: 1.1,
								opacity: 0.8,
								transition: { duration: 0.5 },
							}}
						>
							<Link to={'/pos'} >
								<img className='' src={pos} />
							</Link>
						</motion.div>
					</div>
					<div className='w-full md:w-11/12 mx-auto mt-10 md:mt-20 px-2 md:px-20 md:flex'>
						<motion.div
							className="w-full md:w-1/2 mt-10 md:mt-0"
							initial={"offscreen"}
							whileInView={"onscreen"}
							viewport={{ once: true, amount: 0.1 }}
							variants={areaAnimateLR}
							whileHover={{
								scale: 1.1,
								opacity: 0.8,
								transition: { duration: 0.5 },
							}}
						>
							<img className='' src={paginas} />
						</motion.div>
						<div className='w-full md:w-3/4 md:pr-10 grid content-center'>
							<motion.p
								className='text-black font-sans font-semibold text-3xl text-right'
								initial={"offscreen"}
								whileInView={"onscreen"}
								viewport={{ once: true, amount: 0.1 }}
								variants={areaAnimateRL}
							>
								{lang === "EN" ? 'Páginas Web a medida' : 'Customized websites.'}
							</motion.p>
							<motion.p
								className='mt-5 text-black font-sans font-extralight text-xl text-right'
								initial={"offscreen"}
								whileInView={"onscreen"}
								viewport={{ once: true, amount: 0.1 }}
								variants={areaAnimateRL}
							>
								{lang === "EN" ? '¿Necesitas una presencia en línea profesional para tu negocio? ¡Tenemos la solución perfecta para ti! Ofrecemos desarrollo de páginas web personalizadas y adaptadas a tus necesidades específicas. Con nuestros diseños atractivos y funcionales, podemos ayudarte a destacar en línea y atraer a más clientes. Además, nuestro equipo de expertos está siempre disponible para brindarte soporte y asegurarse de que tu sitio web funcione sin problemas. ¡No esperes más y haz que tu negocio brille en línea con nuestro servicio de desarrollo de páginas web hoy mismo!' :
									"Do you need a professional online presence for your business? We have the perfect solution for you! We offer custom web development tailored to your specific needs. With our attractive and functional designs, we can help you stand out online and attract more customers. Additionally, our team of experts is always available to provide support and make sure your website runs smoothly. Don't wait any longer and make your business shine online with our web development service today!"}</motion.p>
						</div>
					</div>
					<h3 className='text-center font-semibold text-3xl mt-20 uppercase'>Nuestros Clientes</h3>
					<motion.div
						className='w-full md:w-11/12 mx-auto flex mt-5 pb-24'
						initial={"offscreen"}
						whileInView={"onscreen"}
						viewport={{ once: true, amount: 0.1 }}
						variants={areaAnimateDU}
					>
						<Clientes />
					</motion.div>
				</div>
			</main>
		</Layout>
	)
}

export default Home