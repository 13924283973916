import { useContext } from 'react';
import Layout from '../components/Layout'
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import ContextStates from '../context/ContextStates';
import { areaAnimateLR, areaAnimateS, areaAnimateRL, areaAnimateDU } from '../components/Animaciones';
import cmms from '../assets/viewequipo1.png'
import cmmsindex from '../assets/cmms-index.png'
import cmmsview from '../assets/cmmsviewequipo.png'
import cmmsot from '../assets/cmmsot.png'
import Planes from '../assets/Planes.png'
import Solicitudes from '../assets/Solicitudes.png'
import app1 from '../assets/app1.png'
import app2 from '../assets/app2.png'
import Mob3 from '../assets/mob3.jpg'
import Mob4 from '../assets/mob4.jpg'
import Mob5 from '../assets/mob5.jpg'

const Cmms = () => {
	const { lang } = useContext(ContextStates);


	return (
		<Layout
			page={"CMMS"}
		>
			<main className='w-full mb-10 font-sans'>
				<div
					className="w-10/12 mx-auto mt-10 pt-5"
				>
					<motion.h2
						initial={"offscreen"}
						whileInView={"onscreen"}
						viewport={{ once: true, amount: 0.1 }}
						variants={areaAnimateS}
						className='text-center font-sans text-5xl text-black mt-10'
					>{lang === "EN" ? 'Sistema de Gestión de Mantenimiento Asistido por Computadora (CMMS)' : 'Computerized Maintenance Management System (CMMS)'}</motion.h2>
					<motion.div className="md:w-11/12 w-full mx-auto mt-10 md:mt-10 p-3 md:p-20 rounded-xl shadow-lg"
						initial={"offscreen"}
						whileInView={"onscreen"}
						viewport={{ once: true, amount: 0.1 }}
						variants={areaAnimateS}
						whileHover={{
							//scale: 1.1,
							//transition: { duration: 1 },
						}}
					>
						<img className='rounded-xl' src={cmmsindex} />
					</motion.div>
					<motion.div className="md:w-11/12 w-full mx-auto mt-10 md:mt-20 p-3 md:p-20 shadow-lg bg-gradient-to-r from-fuchsia-500 to-pink-500 rounded-xl"
						initial={"offscreen"}
						whileInView={"onscreen"}
						viewport={{ once: true, amount: 0.1 }}
						variants={areaAnimateLR}
						whileHover={{
							//scale: 1.1,
							//transition: { duration: 1 },
						}}
					>
						<p className='text-white text-4xl'>{lang === "EN" ? `¡Mantenga su equipo y sus activos en óptimas condiciones con nuestro software CMMS! Con nuestra solución fácil de usar, podrá programar y realizar tareas de mantenimiento preventivo, registrar y solucionar problemas técnicos, y gestionar el inventario de piezas de repuesto de manera eficiente. ¡Nuestro software CMMS lo ayudará a optimizar sus procesos de mantenimiento y a ahorrar tiempo y dinero en el proceso!
                        No más papeles ni sistemas separados para diferentes tareas. Con nuestro software CMMS, todo está en un solo lugar y es fácilmente accesible desde cualquier lugar y en cualquier momento. Además, podrá generar informes personalizados y obtener una visión en tiempo real del estado de sus activos y tareas de mantenimiento pendientes.No espere más, ¡mejore su eficiencia y aumente la disponibilidad de sus activos con nuestro software CMMS! Contáctanos hoy mismo para obtener más información.`:
							`Keep your team and assets in top condition with our CMMS software! With our user-friendly solution, you can schedule and perform preventive maintenance tasks, record and resolve technical issues, and manage spare parts inventory efficiently. Our CMMS software will help you optimize your maintenance processes and save time and money in the process!

                        No more papers or separate systems for different tasks. With our CMMS software, everything is in one place and easily accessible from anywhere and at any time. In addition, you can generate custom reports and get a real-time view of the status of your assets and pending maintenance tasks.
                        
                        Don't wait any longer, improve your efficiency and increase the availability of your assets with our CMMS software! Contact us today for more information and a free demo.`}</p>
					</motion.div>
					<motion.h4
						className='text-black font-sans mt-10 text-4xl text-center'
						initial={"offscreen"}
						whileInView={"onscreen"}
						viewport={{ once: true, amount: 0.1 }}
						variants={areaAnimateS}
						whileHover={{
							//scale: 1.1,
							//transition: { duration: 1 },
						}}
					>{lang === "EN" ? 'Algunas características' : 'Some features'}</motion.h4>
					<div className='w-full md:w-11/12 mx-auto mt-10 md:mt-20 px-2 md:px-20 md:flex'>
						<div className='w-full md:w-3/4 pr-5 grid content-center'>
							<motion.p
								className='text-black font-sans font-semibold text-3xl'
								initial={"offscreen"}
								whileInView={"onscreen"}
								viewport={{ once: true, amount: 0.1 }}
								variants={areaAnimateLR}
							>
								{lang === "EN" ? 'Control de activos' : 'Asset management'}
							</motion.p>
							<motion.p
								className='mt-5 text-black font-sans font-extralight text-xl'
								initial={"offscreen"}
								whileInView={"onscreen"}
								viewport={{ once: true, amount: 0.1 }}
								variants={areaAnimateLR}
							>
								{lang === "EN" ? `El control de activos es una de las funciones principales de un sistema CMMS (Maintenance Management System). Permite a las empresas registrar y monitorear el estado de sus activos, tales como maquinarias, equipos, instalaciones y otros bienes críticos para su negocio. Con un sistema CMMS, las empresas pueden realizar un seguimiento de la historia de mantenimiento de cada activo, programar y realizar tareas de mantenimiento preventivo, y solucionar problemas técnicos de manera oportuna. Además, un sistema CMMS permite a las empresas generar informes y estadísticas sobre el rendimiento de sus activos, lo que les permite tomar decisiones informadas sobre su estrategia de mantenimiento y mejorar la eficiencia de sus procesos. En resumen, el control de activos de un sistema CMMS es esencial para mantener la disponibilidad y el rendimiento óptimos de los activos de una empresa.` :
									`Asset tracking is one of the main functions of a CMMS (Maintenance Management System). It allows companies to record and monitor the status of their assets, such as machinery, equipment, facilities, and other critical business assets. With a CMMS system, companies can track the maintenance history of each asset, schedule and perform preventive maintenance tasks, and resolve technical problems in a timely manner. Additionally, a CMMS system enables companies to generate reports and statistics on the performance of their assets, allowing them to make informed decisions about their maintenance strategy and improve the efficiency of their processes. In conclusion, the asset tracking of a CMMS system is essential for maintaining the availability and optimal performance of a company's assets.`}
							</motion.p>
						</div>
						<motion.div
							className="w-full md:w-1/2 mt-10 md:mt-0"
							initial={"offscreen"}
							whileInView={"onscreen"}
							viewport={{ once: true, amount: 0.1 }}
							variants={areaAnimateRL}
							whileHover={{
								scale: 1.1,
								opacity: 0.8,
								transition: { duration: 0.5 },
							}}
						>

							<img className='rounded-t-xl' src={cmmsview} />
							<img className='rounded-b-xl' src={cmms} />
						</motion.div>
					</div>
					<div className='w-full md:w-11/12 mx-auto mt-10 md:mt-20 px-2 md:px-20 md:flex'>
						<motion.div
							className="w-full md:w-1/2 mt-10 md:mt-0"
							initial={"offscreen"}
							whileInView={"onscreen"}
							viewport={{ once: true, amount: 0.1 }}
							variants={areaAnimateLR}
							whileHover={{
								scale: 1.1,
								opacity: 0.8,
								transition: { duration: 0.5 },
							}}
						>
							<img className='rounded-xl' src={cmmsot} />
						</motion.div>
						<div className='w-full md:w-3/4 md:pl-10 grid content-center'>
							<motion.p
								className='text-black font-sans font-semibold text-3xl text-right'
								initial={"offscreen"}
								whileInView={"onscreen"}
								viewport={{ once: true, amount: 0.1 }}
								variants={areaAnimateRL}
							>
								{lang === "EN" ? 'Órdenes de Trabajo' : 'Work Orders'}
							</motion.p>
							<motion.p
								className='mt-5 text-black font-sans text-xl font-extralight text-right'
								initial={"offscreen"}
								whileInView={"onscreen"}
								viewport={{ once: true, amount: 0.1 }}
								variants={areaAnimateRL}
							>
								{lang === "EN" ? '¡La "Orden de Trabajo de un Sistema CMMS" le permite programar y realizar tareas de mantenimiento preventivo, registrar y solucionar problemas técnicos, y gestionar el inventario de piezas de repuesto de manera eficiente. Además, podrá generar informes personalizados y obtener una visión en tiempo real del estado de sus activos y tareas de mantenimiento pendientes. ¡Optimice sus procesos de mantenimiento y ahorre tiempo y dinero con la "Orden de Trabajo de un Sistema CMMS"!' :
									'The "Work Order of a CMMS System" allows you to schedule and perform preventive maintenance tasks, record and solve technical problems, and manage spare parts inventory efficiently. In addition, you can generate customized reports and get a real-time view of the status of your assets and pending maintenance tasks. Optimize your maintenance processes and save time and money with the "Work Order of a CMMS System"!'}
							</motion.p>
						</div>
					</div>
					<div className='w-full md:w-11/12 mx-auto mt-10 md:mt-20 px-2 md:px-20 md:flex'>
						<div className='w-full md:w-3/4 pr-5 grid content-center'>
							<motion.p
								className='text-black font-sans font-semibold text-3xl'
								initial={"offscreen"}
								whileInView={"onscreen"}
								viewport={{ once: true, amount: 0.1 }}
								variants={areaAnimateLR}
							>
								{lang === "EN" ? 'Planes de mantenimiento' : 'Maintenance Plans'}
							</motion.p>
							<motion.p
								className='mt-5 text-black font-sans font-extralight text-xl'
								initial={"offscreen"}
								whileInView={"onscreen"}
								viewport={{ once: true, amount: 0.1 }}
								variants={areaAnimateLR}
							>
								{lang === "EN" ? `Los planes de mantenimiento son programas predefinidos que establecen las actividades de mantenimiento preventivo que deben realizarse en activos específicos.` :
									`Maintenance plans are predefined programs that establish preventive maintenance activities to be performed on specific assets.`}
							</motion.p>
							<motion.p
								className='mt-5 text-black font-sans font-extralight text-xl'
								initial={"offscreen"}
								whileInView={"onscreen"}
								viewport={{ once: true, amount: 0.1 }}
								variants={areaAnimateLR}
							>
								{lang === "EN" ? `Incluyen tareas recurrentes como inspecciones, lubricaciones, calibraciones, y otras acciones planificadas para mantener los activos en óptimas condiciones de funcionamiento.` :
									`They include recurrent tasks such as inspections, lubrications, calibrations, and other planned actions to keep assets in optimal operating conditions.`}
							</motion.p>
							<motion.p
								className='mt-5 text-black font-sans font-extralight text-xl'
								initial={"offscreen"}
								whileInView={"onscreen"}
								viewport={{ once: true, amount: 0.1 }}
								variants={areaAnimateLR}
							>
								{lang === "EN" ? `Los planes de mantenimiento pueden ser basados en el tiempo, en el uso (horas de funcionamiento, ciclos de producción, etc.), o en condiciones específicas del activo.` :
									`Maintenance plans can be based on time, usage (operating hours, production cycles, etc.), or specific asset conditions.`}
							</motion.p>
							<motion.p
								className='mt-5 text-black font-sans font-extralight text-xl'
								initial={"offscreen"}
								whileInView={"onscreen"}
								viewport={{ once: true, amount: 0.1 }}
								variants={areaAnimateLR}
							>
								{lang === "EN" ? `Ayudan a minimizar el riesgo de fallas y a prolongar la vida útil de los activos al mantenerlos en condiciones óptimas.` :
									`They help minimize the risk of failures and extend the lifespan of assets by keeping them in optimal conditions.`}
							</motion.p>
						</div>
						<motion.div
							className="w-full md:w-1/2 mt-10 md:mt-0"
							initial={"offscreen"}
							whileInView={"onscreen"}
							viewport={{ once: true, amount: 0.1 }}
							variants={areaAnimateRL}
							whileHover={{
								scale: 1.1,
								opacity: 0.8,
								transition: { duration: 0.5 },
							}}
						>

							<img className='rounded-t-xl' src={Planes} />
						</motion.div>
					</div>
					<div className='w-full md:w-11/12 mx-auto mt-10 md:mt-20 px-2 md:px-20 md:flex'>
						<motion.div
							className="w-full md:w-1/2 mt-10 md:mt-0"
							initial={"offscreen"}
							whileInView={"onscreen"}
							viewport={{ once: true, amount: 0.1 }}
							variants={areaAnimateLR}
							whileHover={{
								scale: 1.1,
								opacity: 0.8,
								transition: { duration: 0.5 },
							}}
						>
							<img className='rounded-xl' src={Solicitudes} />
						</motion.div>
						<div className='w-full md:w-3/4 md:pl-10 grid content-center'>
							<motion.p
								className='text-black font-sans font-semibold text-3xl text-right'
								initial={"offscreen"}
								whileInView={"onscreen"}
								viewport={{ once: true, amount: 0.1 }}
								variants={areaAnimateRL}
							>
								{lang === "EN" ? 'Solicitudes de Trabajo' : 'Work Requests'}
							</motion.p>
							<motion.p
								className='mt-5 text-black font-sans text-xl font-extralight text-right'
								initial={"offscreen"}
								whileInView={"onscreen"}
								viewport={{ once: true, amount: 0.1 }}
								variants={areaAnimateRL}
							>
								{lang === "EN" ? 'Las solicitudes de trabajo son solicitudes generadas por usuarios o equipos para solicitar mantenimiento, reparaciones o servicios relacionados con activos físicos dentro de una organización.' :
									'Work requests are requests generated by users or teams to request maintenance, repairs, or services related to physical assets within an organization.'}
							</motion.p>
							<motion.p
								className='mt-5 text-black font-sans text-xl font-extralight text-right'
								initial={"offscreen"}
								whileInView={"onscreen"}
								viewport={{ once: true, amount: 0.1 }}
								variants={areaAnimateRL}
							>
								{lang === "EN" ? 'Incluyen detalles como la descripción del problema, la prioridad, la ubicación del activo, la identificación del activo, la fecha y hora de la solicitud, los detalles del solicitante y cualquier instrucción adicional relevante.' :
									'They include details such as problem description, priority, asset location, asset identification, request date and time, requester details, and any relevant additional instructions.'}
							</motion.p>
							<motion.p
								className='mt-5 text-black font-sans text-xl font-extralight text-right'
								initial={"offscreen"}
								whileInView={"onscreen"}
								viewport={{ once: true, amount: 0.1 }}
								variants={areaAnimateRL}
							>
								{lang === "EN" ? 'Las solicitudes de trabajo pueden ser generadas por usuarios finales, personal de mantenimiento durante inspecciones rutinarias, o como respuesta a problemas detectados durante el funcionamiento normal de los activos.' :
									'Work requests can be generated by end-users, maintenance personnel during routine inspections, or in response to issues detected during normal asset operation.'}
							</motion.p>
							<motion.p
								className='mt-5 text-black font-sans text-xl font-extralight text-right'
								initial={"offscreen"}
								whileInView={"onscreen"}
								viewport={{ once: true, amount: 0.1 }}
								variants={areaAnimateRL}
							>
								{lang === "EN" ? 'Se registran en el CMMS para su asignación a técnicos de mantenimiento, seguimiento de progreso y resolución, y mantenimiento de un historial completo de mantenimiento.' :
									'They are recorded in the CMMS for assignment to maintenance technicians, progress tracking and resolution, and maintaining a complete maintenance history.'}
							</motion.p>
						</div>
					</div>
					<div className='w-full md:w-11/12 mx-auto mt-10 md:mt-20 px-2 md:px-20 md:flex'>
						<motion.p
							className='text-black font-sans text-xl font-extralight'
							initial={"offscreen"}
							whileInView={"onscreen"}
							viewport={{ once: true, amount: 0.1 }}
							variants={areaAnimateS}
							whileHover={{
								//scale: 1.1,
								//transition: { duration: 1 },
							}}
						>{lang === "EN" ? 'En resumen, los planes de mantenimiento son programas predefinidos para mantener activos en condiciones óptimas, mientras que las solicitudes de trabajo son solicitudes específicas para abordar problemas o necesidades de mantenimiento no planificadas. Ambos elementos son fundamentales en un sistema CMMS para garantizar la eficiencia y la confiabilidad de los activos de una organización.' :
							"In summary, maintenance plans are predefined programs to keep assets in optimal conditions, while work requests are specific requests to address unplanned maintenance needs or issues. Both elements are essential in a CMMS system to ensure the efficiency and reliability of an organization's assets."}</motion.p>
					</div>

					<motion.h4
						className='text-black font-sans mt-10 text-4xl text-center'
						initial={"offscreen"}
						whileInView={"onscreen"}
						viewport={{ once: true, amount: 0.1 }}
						variants={areaAnimateS}
						whileHover={{
							//scale: 1.1,
							//transition: { duration: 1 },
						}}
					>{lang === "EN" ? 'Aplicación móvil' : 'App mobile'}</motion.h4>
					<div className='w-full md:w-11/12 mx-auto mt-10 md:mt-20 px-2 md:px-20 md:flex'>
						<div className='w-full md:w-3/4 pr-5 grid content-center'>
							<motion.p
								className='mt-5 text-black font-sans font-extralight text-xl'
								initial={"offscreen"}
								whileInView={"onscreen"}
								viewport={{ once: true, amount: 0.1 }}
								variants={areaAnimateLR}
							>
								{lang === "EN" ? `Acceso Remoto y en Tiempo Real: Permite a los técnicos y al personal de mantenimiento acceder al sistema desde cualquier ubicación, en cualquier momento, lo que facilita la gestión de solicitudes de trabajo, la actualización del estado de las tareas y la revisión de la documentación del activo en tiempo real.` :
									`Maintenance plans are predefined programs that establish preventive maintenance activities to be performed on specific assets.`}
							</motion.p>
							<motion.p
								className='mt-5 text-black font-sans font-extralight text-xl'
								initial={"offscreen"}
								whileInView={"onscreen"}
								viewport={{ once: true, amount: 0.1 }}
								variants={areaAnimateLR}
							>
								{lang === "EN" ? `Facilita la Entrada de Datos en el Sitio: Los técnicos pueden ingresar datos directamente en el sistema mientras están en el sitio, lo que reduce la posibilidad de errores y retrasos en la documentación. Esto incluye la captura de datos de inspección, registros de mantenimiento, imágenes de activos y cualquier otra información relevante.` :
									`They include recurrent tasks such as inspections, lubrications, calibrations, and other planned actions to keep assets in optimal operating conditions.`}
							</motion.p>
							<motion.p
								className='mt-5 text-black font-sans font-extralight text-xl'
								initial={"offscreen"}
								whileInView={"onscreen"}
								viewport={{ once: true, amount: 0.1 }}
								variants={areaAnimateLR}
							>
								{lang === "EN" ? `Mejora la Eficiencia Operativa: Al permitir que los técnicos accedan rápidamente a la información y registren las actividades en tiempo real, se reduce el tiempo perdido en la comunicación y la documentación manual. Esto ayuda a mejorar la eficiencia operativa y reduce los tiempos de inactividad no planificados.` :
									`Maintenance plans can be based on time, usage (operating hours, production cycles, etc.), or specific asset conditions.`}
							</motion.p>
						</div>
						<motion.div
							className="w-full md:w-1/2 mt-10 md:mt-0"
							initial={"offscreen"}
							whileInView={"onscreen"}
							viewport={{ once: true, amount: 0.1 }}
							variants={areaAnimateRL}
							whileHover={{
								scale: 1.1,
								opacity: 0.8,
								transition: { duration: 0.5 },
							}}
						>

							<img className='rounded-t-xl' src={app1} />
						</motion.div>
					</div>
					<div className='w-full md:w-11/12 mx-auto mt-10 md:mt-20 px-2 md:px-20 md:flex'>
						<motion.div
							className="w-full md:w-1/2 mt-10 md:mt-0"
							initial={"offscreen"}
							whileInView={"onscreen"}
							viewport={{ once: true, amount: 0.1 }}
							variants={areaAnimateLR}
							whileHover={{
								scale: 1.1,
								opacity: 0.8,
								transition: { duration: 0.5 },
							}}
						>
							<img className='rounded-xl' src={app2} />
						</motion.div>
						<div className='w-full md:w-3/4 md:pl-10 grid content-center'>
							<motion.p
								className='mt-5 text-black font-sans font-extralight text-xl'
								initial={"offscreen"}
								whileInView={"onscreen"}
								viewport={{ once: true, amount: 0.1 }}
								variants={areaAnimateRL}
							>
								{lang === "EN" ? `Notificaciones Instantáneas: Las aplicaciones móviles pueden enviar notificaciones instantáneas sobre nuevas solicitudes de trabajo, cambios en el estado de las tareas o alertas de mantenimiento, lo que permite una respuesta rápida y una mejor gestión de prioridades.` :
									`They help minimize the risk of failures and extend the lifespan of assets by keeping them in optimal conditions.`}
							</motion.p>
							<motion.p
								className='mt-5 text-black font-sans font-extralight text-xl'
								initial={"offscreen"}
								whileInView={"onscreen"}
								viewport={{ once: true, amount: 0.1 }}
								variants={areaAnimateRL}
							>
								{lang === "EN" ? `Integración de Funciones del Dispositivo Móvil: Las aplicaciones móviles pueden aprovechar las capacidades de los dispositivos móviles, como la cámara, el escáner de códigos de barras y el GPS, para facilitar la captura de datos y la identificación de activos.` :
									`They help minimize the risk of failures and extend the lifespan of assets by keeping them in optimal conditions.`}
							</motion.p>
							<motion.p
								className='mt-5 text-black font-sans font-extralight text-xl'
								initial={"offscreen"}
								whileInView={"onscreen"}
								viewport={{ once: true, amount: 0.1 }}
								variants={areaAnimateRL}
							>
								{lang === "EN" ? `Mayor Flexibilidad y Adaptabilidad: La movilidad permite que el personal de mantenimiento responda de manera más rápida y efectiva a las necesidades cambiantes del entorno de trabajo, lo que aumenta la capacidad de adaptación de la organización.` :
									`They help minimize the risk of failures and extend the lifespan of assets by keeping them in optimal conditions.`}
							</motion.p>
						</div>
					</div>

					<div className="w-10/12 mx-auto mt-10 pt-5">
						<motion.h2
							initial={"offscreen"}
							whileInView={"onscreen"}
							viewport={{ once: true, amount: 0.1 }}
							variants={areaAnimateS}
							className='text-center font-sans text-5xl text-black mt-10'
						>{lang === "EN" ? '¿Por qué elegir el CMMS de Mirx Software?' : 'Why Choose the CMMS from Mirx Software?'}</motion.h2>
						<div className="md:w-11/12 w-full mx-auto mt-2 p-3">
							<motion.p
								className='mt-5 text-black font-sans font-extralight text-xl'
								initial={"offscreen"}
								whileInView={"onscreen"}
								viewport={{ once: true, amount: 0.1 }}
								variants={areaAnimateRL}
							>{lang === "EN" ? `En Mirx Software, nos enorgullece ofrecer un sistema CMMS (Computerized Maintenance Management System) de vanguardia que impulsa la eficiencia operativa de su empresa. Nuestro compromiso es brindarle una solución CMMS líder en la industria respaldada por un servicio excepcional. He aquí las principales razones para elegir nuestro CMMS:` :
								`At Mirx Software, we take pride in offering a cutting-edge Computerized Maintenance Management System (CMMS) that drives operational efficiency for your business. Our commitment is to provide you with an industry-leading CMMS solution backed by exceptional service. Here are the top reasons to choose our CMMS:`}</motion.p>
							<ul className='list-inside list-disc p-10'>

								<motion.li
									className='mt-5 text-black font-sans font-extralight text-xl'
									initial={"offscreen"}
									whileInView={"onscreen"}
									viewport={{ once: true, amount: 0.1 }}
									variants={areaAnimateRL}
								>{lang === "EN" ? `Pruébelo sin riesgo con nuestra garantía de devolución de dinero. Tiene un mes completo para evaluar el sistema y asegurarse de que cumpla con las expectativas de su empresa. Si no queda satisfecho, le reembolsaremos el 100%.` :
									`Try it risk-free with our money-back guarantee. You have a full month to evaluate the system and ensure it meets your company's expectations. If you're not satisfied, we'll refund 100% of your money.`}</motion.li>
								<motion.li
									className='mt-5 text-black font-sans font-extralight text-xl'
									initial={"offscreen"}
									whileInView={"onscreen"}
									viewport={{ once: true, amount: 0.1 }}
									variants={areaAnimateRL}
								>{lang === "EN" ? `Acceso total a todas las funciones del sistema sin limitaciones. En todos nuestros planes, puede aprovechar al máximo las capacidades del CMMS sin restricciones de usuarios o módulos.` :
									`Unlimited access to all system features with no restrictions. Across all our plans, you can take full advantage of the CMMS capabilities without any limitations on users or modules.`}</motion.li>
								<motion.li
									className='mt-5 text-black font-sans font-extralight text-xl'
									initial={"offscreen"}
									whileInView={"onscreen"}
									viewport={{ once: true, amount: 0.1 }}
									variants={areaAnimateRL}
								>{lang === "EN" ? `Personalización total según sus necesidades comerciales. Nuestro sistema CMMS es altamente personalizable para adaptarse a los procesos y requisitos únicos de su organización.` :
									`Total customization to your business needs. Our CMMS is highly customizable to adapt to your organization's unique processes and requirements.`}</motion.li>
								<motion.li
									className='mt-5 text-black font-sans font-extralight text-xl'
									initial={"offscreen"}
									whileInView={"onscreen"}
									viewport={{ once: true, amount: 0.1 }}
									variants={areaAnimateRL}
								>{lang === "EN" ? `Aplicación móvil para control en tiempo real. Nuestra aplicación móvil le permite monitorear y administrar todas las operaciones de mantenimiento desde cualquier lugar.` :
									`Mobile app for real-time control. Our mobile app allows you to monitor and manage all maintenance operations from anywhere.`}</motion.li>
								<motion.li
									className='mt-5 text-black font-sans font-extralight text-xl'
									initial={"offscreen"}
									whileInView={"onscreen"}
									viewport={{ once: true, amount: 0.1 }}
									variants={areaAnimateRL}
								>{lang === "EN" ? `Actualizaciones y mejoras continuas. Nuestro equipo trabaja constantemente en mejorar el software y la aplicación móvil para brindarle las últimas funciones y optimizaciones.` :
									`Continuous updates and improvements. Our team is constantly working to enhance the software and mobile app to provide you with the latest features and optimizations.`}</motion.li>
								<motion.li
									className='mt-5 text-black font-sans font-extralight text-xl'
									initial={"offscreen"}
									whileInView={"onscreen"}
									viewport={{ once: true, amount: 0.1 }}
									variants={areaAnimateRL}
								>{lang === "EN" ? `Soporte técnico rápido e ilimitado. Nuestros expertos en soporte están disponibles para ayudarlo en cualquier momento, asegurando una implementación y uso sin problemas.` :
									`Rapid and unlimited technical support. Our support experts are available to assist you at any time, ensuring a smooth implementation and usage.`}</motion.li>
							</ul>
							<motion.p
								className='mt-5 text-black font-sans font-extralight text-xl'
								initial={"offscreen"}
								whileInView={"onscreen"}
								viewport={{ once: true, amount: 0.1 }}
								variants={areaAnimateRL}
							>{lang === "EN" ? `En Mirx Software, estamos comprometidos a ser su socio de confianza en la gestión de mantenimiento de activos. Elija nuestro CMMS para optimizar sus operaciones con una sólida plataforma respaldada por un servicio excepcional. ¡Contáctenos hoy para una demostración!` :
								`At Mirx Software, we are committed to being your trusted partner in asset maintenance management. Choose our CMMS to optimize your operations with a robust platform backed by exceptional service. Contact us today for a demo!`}</motion.p>
						</div>
					</div>

					<motion.h2
						initial={"offscreen"}
						whileInView={"onscreen"}
						viewport={{ once: true, amount: 0.1 }}
						variants={areaAnimateS}
						className='text-center font-sans text-5xl text-black mt-28'
					>{lang === "EN" ? 'Nuestros planes comerciales' : 'Our commercial plans'}</motion.h2>

					<motion.div
						className="w-full md:w-11/12 mx-auto mt-5 flex"
						initial={"offscreen"}
						whileInView={"onscreen"}
						viewport={{ once: true, amount: 0.1 }}
						variants={areaAnimateS}
					>
						<motion.div className="md:w-1/3 w-full mx-4 mt-10 md:mt-20 p-3 md:p-10 shadow-lg bg-gradient-to-r from-purple-700 to-fuchsia-700 rounded-xl"
							initial={"offscreen"}
							whileInView={"onscreen"}
							viewport={{ once: true, amount: 0.1 }}
							variants={areaAnimateLR}
							whileHover={{
								//scale: 1.1,
								//transition: { duration: 1 },
							}}
						>
							<div className='border-b border-fuchsia-600'>
								<p className='text-white text-2xl uppercase'>{lang === "EN" ? `Mensual` : `Monthly`}</p>
							</div>
							<p className='text-white text-4xl mt-10 mb-10 text-center'>$99 <span className='text-sm'>USD</span></p>
						</motion.div>
						<motion.div className="md:w-1/3 w-full mx-4 mt-10 md:mt-20 p-3 md:p-10 shadow-lg bg-gradient-to-r from-purple-700 to-fuchsia-700 rounded-xl"
							initial={"offscreen"}
							whileInView={"onscreen"}
							viewport={{ once: true, amount: 0.1 }}
							variants={areaAnimateLR}
							whileHover={{
								//scale: 1.1,
								//transition: { duration: 1 },
							}}
						>
							<div className='border-b border-fuchsia-600'>
								<p className='text-white text-2xl uppercase'>{lang === "EN" ? `Semestral` : `Semiannual`}</p>
							</div>
							<p className='text-white text-4xl mt-10 mb-10 text-center'>$79 <span className='text-sm'>USD {lang === "EN" ? `/mes` : `/month`}</span></p>
						</motion.div>
						<motion.div className="md:w-1/3 w-full mx-4 mt-10 md:mt-20 p-3 md:p-10 shadow-lg bg-gradient-to-r from-purple-700 to-fuchsia-700 rounded-xl"
							initial={"offscreen"}
							whileInView={"onscreen"}
							viewport={{ once: true, amount: 0.1 }}
							variants={areaAnimateLR}
							whileHover={{
								//scale: 1.1,
								//transition: { duration: 1 },
							}}
						>
							<div className='border-b border-fuchsia-600'>
								<p className='text-white text-2xl uppercase'>{lang === "EN" ? `Anual` : `Annual`}</p>
							</div>
							<p className='text-white text-4xl mt-10 mb-10 text-center'>$59 <span className='text-sm'>USD {lang === "EN" ? `/mes` : `/month`}</span></p>
						</motion.div>

					</motion.div>
					<div className='w-full md:w-11/12 mx-auto p-5'>
						<motion.h2
							initial={"offscreen"}
							whileInView={"onscreen"}
							viewport={{ once: true, amount: 0.1 }}
							variants={areaAnimateS}
							className='text-center font-sans text-3xl text-black mt-10'
						>{lang === "EN" ? 'Todos nuestros planes incluyen:' : 'Our commercial plans'}</motion.h2>
						<ul className='list-inside list-disc'>
							<motion.li
								initial={"offscreen"}
								whileInView={"onscreen"}
								viewport={{ once: true, amount: 0.1 }}
								variants={areaAnimateRL}
								className='text-left font-sans text-lg text-black mt-5'
							>{lang === "EN" ? 'Usuarios Ilimitados: Todos los planes permiten a un número ilimitado de usuarios acceder al sistema CMMS.' : 'Unlimited Users: All plans allow an unlimited number of users to access the CMMS system.'}</motion.li>
							<motion.li
								initial={"offscreen"}
								whileInView={"onscreen"}
								viewport={{ once: true, amount: 0.1 }}
								variants={areaAnimateRL}
								className='text-left font-sans text-lg text-black mt-5'
							>{lang === "EN" ? 'Funciones Ilimitadas: Se incluyen todas las funciones necesarias para la gestión de mantenimiento, como seguimiento de activos, programación de mantenimiento, gestión de órdenes de trabajo, historial de mantenimiento, etc.' : 'Unlimited Features: All plans include all the necessary features for maintenance management, such as asset tracking, maintenance scheduling, work order management, maintenance history, etc.'}</motion.li>
							<motion.li
								initial={"offscreen"}
								whileInView={"onscreen"}
								viewport={{ once: true, amount: 0.1 }}
								variants={areaAnimateRL}
								className='text-left font-sans text-lg text-black mt-5'
							>{lang === "EN" ? 'Soporte Ilimitado: Los usuarios tienen acceso ilimitado a soporte técnico para resolver cualquier problema o duda que puedan surgir.' : 'Unlimited Support: Users have unlimited access to technical support to resolve any issues or questions that may arise.'}</motion.li>
							<motion.li
								initial={"offscreen"}
								whileInView={"onscreen"}
								viewport={{ once: true, amount: 0.1 }}
								variants={areaAnimateRL}
								className='text-left font-sans text-lg text-black mt-5'
							>{lang === "EN" ? 'Uso del Sistema y Aplicación Móvil: Se incluye el acceso completo tanto al sistema web como a la aplicación móvil para ingresar al CMMS desde cualquier lugar.' : 'System and Mobile App Usage: Full access to both the web system and the mobile application is included, enabling access to the CMMS from anywhere.'}</motion.li>

						</ul>
					</div>
					<div className='w-full md:w-11/12 mx-auto mt-10 md:mt-20 px-2 md:px-20 md:flex'>
						<div className='w-full md:w-3/4 md:pl-10 grid content-center'>
							<a href='https://cmms.mirx.com.ar' target='_blank' rel="noreferrer">
								<button className='p-4 text-white text-3xl text-center uppercase bg-gradient-to-r from-fuchsia-500 to-pink-500 rounded-xl' type='button'>
									{lang === "EN" ? 'Ver demo' : "Show demo"}
								</button>
							</a>
						</div>
					</div>
				</div>
			</main>
		</Layout>
	)
}

export default Cmms