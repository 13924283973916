import React, { useReducer } from "react";
import authContext from "./authContext";
import authReducer from "./authReducer";
import axios from "axios";

import { Url } from '../../components/Url';

import { 
    REGISTRO_EXITOSO,
    REGISTRO_ERROR,
    LIMPIAR_ALERTA,
    RESTABLECER_REGISTRO,
    LOGIN_EXITOSO,
    LOGIN_ERROR,
    USUARIO_AUTENTICADO,
    CERRAR_SESION

} from "../../types";


const AuthState = ({children}) => {

    const intitialState = {
        token: localStorage.getItem('ld_token'),
        autenticado: null,
        usuario: null,
        mensaje: null,
        nivel: null,
        registradoOk: null,
        modLogin: null,
        modRegistro: null
    }

    //Definir el reducer
    const [ state, dispatch ] = useReducer(authReducer, intitialState);

    //Crear nuevos usuarios
    const registrarUsuario = async datos => {
        const formData=new FormData()
        formData.append('nombre',datos.nombre);
        formData.append('apellido',datos.apellido);
        formData.append('telefono',datos.telefono);
        formData.append('mail',datos.mail);
        formData.append('password',datos.password);

        try{
            const response = await axios({
                url: Url+'api/nuevousuario.php',
                method: 'POST',
                data: formData,
            })
            console.log(response)
            dispatch({
                type:REGISTRO_EXITOSO,
                payload: response.data
            })
            ///limpiar alerta despues de 3 seg

            } catch (e) {
                dispatch({
                    type:REGISTRO_ERROR,
                    payload: "El Email ya corresponde a un Usuario registrado"
                })            }
            setTimeout(() => { 
                dispatch ({
                    type:LIMPIAR_ALERTA
                })
            }, 3000)
            setTimeout(() => { 
                dispatch ({
                    type:RESTABLECER_REGISTRO
                })
            }, 7000)

    }


    //Iniciar sesion

    const iniciarSesion = async datos => {
        const formData=new FormData()
        formData.append('mail',datos.mail);
        formData.append('password',datos.password);

        try{
            const response = await axios({
                url: Url+'api/iniciarsesion.php',
                method: 'POST',
                data: formData,
            })
            console.log(response)
            dispatch({
                type:LOGIN_EXITOSO,
                payload: response.data.results
            })
            ///limpiar alerta despues de 3 seg

        } catch (e) {
            //console.log(e.message)
            dispatch({
                type:LOGIN_ERROR,
                payload: "Email y/o Contraseña incorrecta"
            })
        }
        setTimeout(() => { 
            dispatch ({
                type:LIMPIAR_ALERTA
            })
        }, 3000)

    }

    const iniciarSesionAdmin = async datos => {
        const formData=new FormData()
        formData.append('mail',datos.usuario);
        formData.append('password',datos.password);

        try{
            const response = await axios({
                url: Url+'api/iniciarsesion.php',
                method: 'POST',
                data: formData,
            })
            console.log(response)
            dispatch({
                type:LOGIN_EXITOSO,
                payload: response.data.results
            })
            ///limpiar alerta despues de 3 seg

        } catch (e) {
            //console.log(e.message)
            dispatch({
                type:LOGIN_ERROR,
                payload: "Usuario y/o Contraseña incorrecta"
            })
        }
        setTimeout(() => { 
            dispatch ({
                type:LIMPIAR_ALERTA
            })
        }, 3000)

    }


    // Retorno usuario autenticado JWT

    const usuarioAutenticado = async () => {
        const token = localStorage.getItem('ld_token');
        const webApiUrl = 'https://lideraturismo.com.ar/lidera/api/tokenAuth.php';

        try {
            const response = await axios.get(webApiUrl, { headers: { Authorization: `Bearer ${token}`} });
            dispatch({
                type:USUARIO_AUTENTICADO,
                payload: response.data.results.usuario
            })
            //console.log(response.data.results.usuario)
        } catch (error) {
            //console.log(error)
        }
    }

    const cerrarSesion = async () => {
        const token = localStorage.getItem('ld_token');
        const webApiUrl = 'https://lideraturismo.com.ar/lidera/api/cerrarsesion.php';

        try {
            const response = await axios.get(webApiUrl, { headers: { Authorization: `Bearer ${token}`} });
            dispatch({
                type:CERRAR_SESION
            })
            console.log(response)
        } catch (error) {
            //console.log(error)
        }
    }


    return (
        <authContext.Provider
            value={{
                token: state.token,
                autenticado: state.autenticado,
                usuario: state.usuario,
                nivel: state.nivel,
                mensaje: state.mensaje,
                registradoOk: state.registradoOk,
                registrarUsuario,
                iniciarSesion,
                iniciarSesionAdmin,
                usuarioAutenticado,
                cerrarSesion
            }}
        >
            {children}
        </authContext.Provider>
    )
}

export default AuthState;
