import React, { useContext, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import authContext from '../../context/auth/authContext';
import SideBar from './SideBar';
import LoginAdmin from '../../pages/admin/Login';

const Layout = ({ children, pagina }) => {
    const AuthContext = useContext(authContext);
    const { usuarioAutenticado } = AuthContext;
    const tipo = localStorage.getItem('ld_tip');

    useEffect(() => {
        usuarioAutenticado()
    }, []);

    return (
        <HelmetProvider>
            <Helmet>
                <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
                <link rel="manifest" href="/site.webmanifest" />
                <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
                <title>Mirx Software {pagina}</title>
                <meta name="description" content="Mirx Software - CMMS - Software Empresarial e Industrial" />
                <meta property="og:title" content="Mirx Software" />
                <meta property="og:description" content="Empresa de Software Empresarial Lider - CMMS - Ecommerce - E-commerce" />
                <meta property="og:image" content="https://mirx.com.ar/mirx/img/logos/logo.png" />
                <meta property="og:url" content="https://mirx.com.ar" />
                <meta property="og:site_name" content="Mirx Software" />
                <meta property="og:locale" content="es_AR" />
                <meta property="og:type" content="article" />
            </Helmet>
            {tipo === "1" ? (
                <div className='flex'>
                    <div className='w-1/6'>
                        <SideBar />
                    </div>
                    <div className='w-5/6 pt-0'>
                        {children}
                    </div>
                </div>

            ) : (
                <LoginAdmin />
            )
            }
        </HelmetProvider>
    )
}

export default Layout