import { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import AuthState from './context/auth/authState';
import Home from './pages/Home';
import ContextStates from './context/ContextStates';
import ContextEstados from './context/Estados';
import Cmms from './pages/Cmms';
import Pos from './pages/pos';
import HomeAdmin from './pages/admin/Home';
import Mailing from './pages/admin/Mailing';

function App() {
	const [lang, setLang] = useState("EN")
	const [itemSidebar, setItemSidebar] = useState(0)

	return (
		<AuthState>
			<ContextStates.Provider value={{ lang, setLang }}>
				<ContextEstados.Provider value={{
					itemSidebar, setItemSidebar,
				}}>
					<BrowserRouter>
						<Routes>
							<Route path='/' element={<Home />} />
							<Route path='/softcmms' element={<Cmms />} />
							<Route path='/pos' element={<Pos />} />
							<Route path='/admin/' element={<HomeAdmin />} />
							<Route path='/admin/mailing' element={<Mailing />} />
						</Routes>
					</BrowserRouter>
				</ContextEstados.Provider>
			</ContextStates.Provider>
		</AuthState>

	);
}

export default App;
