import { useState, useEffect, useContext, useRef } from 'react';
import { motion } from 'framer-motion';
import Layout from '../../components/admin/Layout';

import axios from 'axios';
import { Url } from '../../components/Url';

const Mailing = () => {
    const [enviado, setEnviado] = useState(0)
    const areaAnimate = {
        offscreen: { x: 0, opacity: 0 },
        onscreen: {
            x: 0,
            opacity: 1,
            transition: {
                type: "spring",
                bounce: 0.4,
                duration: 4
            }
        }
    }

    const handleEnviar = async () => {
        const formData = new FormData()
        setEnviado(1)
        try {
            const response = await axios({
                url: Url + 'api/mailing.php',
                method: 'POST',
                data: formData,
            })
            console.log(response)
            setEnviado(2)
            //setConsultas(response.data.results)
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <Layout
            pagina="Gestión de Consultas"
        >
            <motion.div className='bg-white rounded-2xl p-3 w-full'
                initial={"offscreen"}
                whileInView={"onscreen"}
                viewport={{ once: true, amount: 0.1 }}
                variants={areaAnimate}
            >
                <h3 className='text-gray-600 text-2xl text-center mb-5'>Mailing</h3>
                <div className='w-full'>
                    <div className="bg-white px-4 pb-10 sm:px-6">

                        <div className="bg-white w-full rounded-lg px-5">
                            {enviado === 0 ? (
                                <button
                                    type="button"
                                    className="w-full shadow-sm p-3 bg-blue-500 hover:bg-gray-400 transition-all text-white uppercase font-bold rounded-xl mt-1"
                                    onClick={() => handleEnviar()}
                                >
                                    Enviar Emails
                                </button>
                            ) : enviado === 1 ? (
                                <div className='w-1/3 mx-auto bg-orange-300 p-4 rounded-lg'>
                                    <p className='text-center'>Enviando</p>
                                </div>
                            ) : (
                                <div className='w-1/3 mx-auto bg-green-300 p-4 rounded-lg'>
                                    <p className='text-center'>Se enviaron los mensajes de manera correcta</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </motion.div>
        </Layout>
    )
}

export default Mailing