import { useState, useContext } from 'react';
import { Transition,Disclosure } from '@headlessui/react'
import { BiMenu } from 'react-icons/bi'
import { AiOutlineClose } from 'react-icons/ai'
import { ImWhatsapp } from 'react-icons/im'
import { FaYoutube } from "react-icons/fa";
import { Link } from 'react-router-dom';
import ContextStates from '../context/ContextStates';
import logo from '../assets/logoSimple.png'

const navigation = [
  { name: 'Inicio', href: '/', current: false },
]

export const NavBar = () => {
    const [ open, setOpen ] = useState(false);
    const { lang, setLang } = useContext(ContextStates);

    const chLang = () => {
        if (lang==='ES')
            setLang("EN")
        else
            setLang("ES")
    }
    return (
        <Disclosure as="nav" className="bg-white text-slate-500 shadow-xl">
            <div className="container mx-auto px-2 sm:px-6 lg:px-8">
                <div className="relative flex items-center h-24">
                    <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                        {/* Mobile menu button*/}
                        <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-black hover:text-purple-600 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                            <span className="sr-only">Abrir Menú</span>
                            {open ? (
                                <AiOutlineClose className="block h-6 w-6" aria-hidden="true" />
                            ) : (
                                <BiMenu className="block h-6 w-6" aria-hidden="true" />
                            )}
                        </Disclosure.Button>
                    </div>
                    <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                        <div className="flex-shrink-0 flex items-center mr-10">
                            <Link to="/">
                                <img src={logo} className='object-cover h-6 sm:h-9' />
                            </Link>
                        </div>
                        <div className="hidden sm:block sm:ml-6">
                            <div className="flex space-x-4">
                                <Link
                                    to="/"
                                    className={"text-black hover:shadow-md hover:text-purple-600 font-sans py-2 px-3 mx-2 rounded-md text-lg"}
                                >
                                    {lang==="EN" ? 'Inicio' : "Home"}
                                </Link>
                                <Link
                                    to="/softcmms"
                                    className={"text-black hover:shadow-md hover:text-purple-600 font-sans py-2 px-3 mx-2 rounded-md text-lg"}
                                >
                                    {lang==="EN" ? 'Sistema CMMS' : "CMMS"}
                                </Link>
                                <Link
                                    to="/pos"
                                    className={"text-black hover:shadow-md hover:text-purple-600 font-sans py-2 px-3 mx-2 rounded-md text-lg"}
                                >
                                    {lang==="EN" ? 'Punto de venta (POS)' : "Point of Sale (POS)"}
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div>
                        <button className='text-black text-xl font-sans' type='button' onClick={()=>chLang()}>{lang}</button>
                    </div>
                    <div className='my-auto p-6'>
                        <a
                            className="text-black text-xl mb-20"
                            href='https://wa.me/543813599744'
                            target={"_blank"}
                            rel='noreferrer'
                        >
                            <ImWhatsapp/>
                        </a>
                    </div>
                    <div className='my-auto p-6'>
                        <a
                            className="text-black text-xl mb-20"
                            href={lang==="EN" ? 'https://www.youtube.com/watch?v=sYBX9-1zQ3w':'https://www.youtube.com/watch?v=GJ2JJRp2Rb4'}
                            target={"_blank"}
                            rel='noreferrer'
                        >
                            <FaYoutube />
                        </a>
                    </div>
                </div>
            </div>
            <Disclosure.Panel className="sm:hidden z-50 absolute w-full bg-white/95">
                <div className="px-2 pt-2 pb-3 space-y-1">
                    <Link to="/">
                        <Disclosure.Button
                            as="button"
                            className={"text-black hover:text-purple-600 block py-2 px-3 rounded-md text-base font-medium"}
                        >
                            {lang==="EN" ? 'Inicio' : "Home"}
                        </Disclosure.Button>
                    </Link>
                    <Link to="/softcmms">
                        <Disclosure.Button
                            as="button"
                            className={"text-black hover:text-purple-600 block py-2 px-3 rounded-md text-base font-medium"}
                        >
                            {lang==="EN" ? 'Sistema CMMS' : "CMMS"}
                        </Disclosure.Button>
                    </Link>
                    <Link to="/pos">
                        <Disclosure.Button
                            as="button"
                            className={"text-black hover:text-purple-600 block py-2 px-3 rounded-md text-base font-medium"}
                        >
                            {lang==="EN" ? 'Punto de venta (POS)' : "Point of Sale (POS)"}
                        </Disclosure.Button>
                    </Link>
                </div>
                <div className='px-3 pt-2 mb-5 flex'>
                </div>
            </Disclosure.Panel>
        </Disclosure>
    )
}