import React, { useContext } from 'react'
import authContext from '../../context/auth/authContext';

const Alerta = () => {
    const AuthContext = useContext(authContext);
    const { mensaje } = AuthContext;
  
    return (
        <div className='absolute w-full'>
            <div className='bg-orange-500/90 uppercase text-bold text-center text-white p-3 w-11/12 mx-auto rounded-xl z-50'>
                {mensaje}
            </div>
        </div>
    );
}
 
export default Alerta;