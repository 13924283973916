import React from 'react'
import { Link } from 'react-router-dom'

const SideBar = () => {
  return (
    <div
        className='bg-black md:w-1/6 w-2/12 text-white md:px-4 px-2 py-3 h-screen fixed'
    >
        <ul className="relative">
            <li className="relative">
                <Link className="flex items-center text-sm py-4 md:px-4 px-1 h-12 overflow-hidden text-white text-ellipsis whitespace-nowrap rounded hover:bg-gray-700 transition duration-300 ease-in-out" 
                    to="/admin/"
                >
                    Inicio
                </Link>
            </li>
            <li className="relative">
                <Link className="flex items-center text-sm py-4 md:px-4 px-1 h-12 overflow-hidden text-white text-ellipsis whitespace-nowrap rounded hover:bg-gray-700 transition duration-300 ease-in-out" 
                    to="/admin/gestionconsultas"
                >
                    Consultas
                </Link>
            </li>
            <li className="relative">
                <Link className="flex items-center text-sm py-4 md:px-4 px-1 h-12 overflow-hidden text-white text-ellipsis whitespace-nowrap rounded hover:bg-gray-700 transition duration-300 ease-in-out" 
                    to="/admin/gestionclientes"
                >
                    Gestión de Clientes
                </Link>
            </li>
            <li className="relative">
                <Link className="flex items-center text-sm py-4 md:px-4 px-1 h-12 overflow-hidden text-white text-ellipsis whitespace-nowrap rounded hover:bg-gray-700 transition duration-300 ease-in-out" 
                    to="/admin/mailing"
                >
                    Mails
                </Link>
            </li>
            <li className="relative">
                <Link className="flex items-center text-sm py-4 md:px-4 px-1 h-12 overflow-hidden text-white text-ellipsis whitespace-nowrap rounded hover:bg-gray-700 transition duration-300 ease-in-out" 
                    to="/admin/configuracion"
                >
                    Configuración
                </Link>
            </li>
            <li className="relative">
                <Link className="flex items-center text-sm py-4 md:px-4 px-1 h-12 overflow-hidden text-white text-ellipsis whitespace-nowrap rounded hover:bg-gray-700 transition duration-300 ease-in-out" 
                    to="/"
                >
                    Salir
                </Link>
            </li>
        </ul>
    </div>
  )
}

export default SideBar