import { useState, useEffect } from 'react';
import axios from 'axios';
import { motion } from 'framer-motion';
import { areaAnimateLR, areaAnimateS, areaAnimateRL, areaAnimateDU } from '../components/Animaciones';


import 'swiper/css/bundle';
import { Url } from './Url';


const Clientes = () => {
    const [empresas, setEmpresas] = useState([]);

    useEffect(() => {
        const getEmpresas = async () => {
            const url = Url + 'api/listempresas.php';
            const resultado = await axios.get(url);
            setEmpresas(resultado.data.results);
            console.log(resultado)
        }
        getEmpresas();
    }, [])


    return (
        <div className='w-full flex'>
            {typeof empresas !== 'undefined' && empresas.map((item, index) => (
                <motion.div
                    className=' justify-center justify-items-center my-auto p-10' key={index}
                    initial={"offscreen"}
                    whileInView={"onscreen"}
                    viewport={{ once: true, amount: 0.1 }}
                    variants={areaAnimateRL}
                    whileHover={{
                        scale: 1.1,
                        opacity: 0.8,
                        transition: { duration: 0.5 },
                    }}
                >
                    <img className='object-cover' src={Url + item.logo} />
                </motion.div>
            ))}

        </div>
    );
}

export default Clientes