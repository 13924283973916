import React, { useContext, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { NavBar } from './NavBar';
import Footer from './Footer';



const Layout = ({children, pagina}) => {
    return (
        <HelmetProvider>
            <Helmet>
                <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
                <link rel="manifest" href="/site.webmanifest" />
                <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
                <link href="https://fonts.googleapis.com/css2?family=Open+Sans&display=swap" rel="stylesheet"/>
                <title>Mirx Software</title>
                <meta name="description" content="Mirx ofrece soluciones de software CMMS líderes en la industria para optimizar la gestión del mantenimiento en su empresa. Nuestro sistema facilita el seguimiento de activos, programación de mantenimiento, control de inventario y más. Mejore la eficiencia operativa con Mirx CMMS." />
                <meta property="og:title" content="Mirx Software" />        
                <meta property="og:description" content="Mirx ofrece soluciones de software CMMS líderes en la industria para optimizar la gestión del mantenimiento en su empresa. Nuestro sistema facilita el seguimiento de activos, programación de mantenimiento, control de inventario y más. Mejore la eficiencia operativa con Mirx CMMS." />        
                <meta property="og:image" content="https://mirx.com.ar/img/logos/logo.png" />
                <meta property="og:url" content="https://mirx.com.ar" />
                <meta property="og:site_name" content="Mirx Software" />
                <meta property="og:locale" content="es_AR" />
                <meta property="og:type" content="article" />
            </Helmet>
            <div>
            <div className="flex flex-col h-screen justify-between">
            <header className="">
                <NavBar />
            </header>
            <main className="mb-auto mx-auto w-full">
            {children}
            </main>
            <footer>
                <Footer />
            </footer>
        </div>

            </div>
        </HelmetProvider>
    )
}

export default Layout