import { useContext } from 'react';
import Layout from '../components/Layout'
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import ContextStates from '../context/ContextStates';
import { areaAnimateLR, areaAnimateS, areaAnimateRL, areaAnimateDU } from '../components/Animaciones';
import pos from '../assets/index_pos.png'
import pos_stock from '../assets/pos_stock.png'
import pos_reporting from '../assets/pos_reporting.png'
import ReactPlayer from 'react-player'

const Pos = () => {
	const { lang } = useContext(ContextStates);


	return (
		<Layout
			page={"CMMS"}
		>
			<main className='w-full mb-10 font-sans'>
				<div
					className="w-10/12 mx-auto mt-10 pt-5"
				>
					<motion.h2
						initial={"offscreen"}
						whileInView={"onscreen"}
						viewport={{ once: true, amount: 0.1 }}
						variants={areaAnimateS}
						className='text-center font-sans text-5xl text-black mt-10'
					>{lang === "EN" ? 'Sistema de Punto de Venta (POS)' : 'Point of Sale (POS) System'}</motion.h2>
					<motion.div className="md:w-11/12 w-full mx-auto mt-10 md:mt-10 p-3 md:p-20 rounded-xl shadow-lg"
						initial={"offscreen"}
						whileInView={"onscreen"}
						viewport={{ once: true, amount: 0.1 }}
						variants={areaAnimateS}
						whileHover={{
							//scale: 1.1,
							//transition: { duration: 1 },
						}}
					>
						<img className='rounded-xl' src={pos} />
					</motion.div>
					<motion.div className="md:w-11/12 w-full mx-auto mt-10 md:mt-20 p-3 md:p-20 shadow-lg bg-gradient-to-r from-fuchsia-500 to-pink-500 rounded-xl"
						initial={"offscreen"}
						whileInView={"onscreen"}
						viewport={{ once: true, amount: 0.1 }}
						variants={areaAnimateLR}
						whileHover={{
							//scale: 1.1,
							//transition: { duration: 1 },
						}}
					>
						<p className='text-white text-4xl'>{lang === "EN" ? `Optimiza tus ventas, controla tu inventario y haz crecer tu negocio con nuestro POS de última generación.` :
							`Optimize your sales, control your inventory, and grow your business with our next-generation POS.`}</p>
					</motion.div>
					<motion.h4
						className='text-black font-sans mt-10 text-4xl text-center'
						initial={"offscreen"}
						whileInView={"onscreen"}
						viewport={{ once: true, amount: 0.1 }}
						variants={areaAnimateS}
						whileHover={{
							//scale: 1.1,
							//transition: { duration: 1 },
						}}
					>{lang === "EN" ? 'Características Principales:' : 'Key features'}</motion.h4>
					<div className='w-full md:w-11/12 mx-auto mt-10 md:mt-20 px-2 md:px-20 md:flex'>
						<div className='w-full md:w-3/4 pr-5 grid content-center'>
							<motion.p
								className='text-black font-sans font-semibold text-3xl'
								initial={"offscreen"}
								whileInView={"onscreen"}
								viewport={{ once: true, amount: 0.1 }}
								variants={areaAnimateLR}
							>
								{lang === "EN" ? 'Gestión de Ventas' : 'Sales Management'}
							</motion.p>
							<motion.p
								className='mt-5 text-black font-sans font-extralight text-xl'
								initial={"offscreen"}
								whileInView={"onscreen"}
								viewport={{ once: true, amount: 0.1 }}
								variants={areaAnimateLR}
							>
								{lang === "EN" ? `Realiza transacciones rápidas y seguras con una interfaz intuitiva.` :
									`Conduct fast and secure transactions with an intuitive interface.`}
							</motion.p>
						</div>
						<motion.div
							className="w-full md:w-1/2 mt-10 md:mt-0"
							initial={"offscreen"}
							whileInView={"onscreen"}
							viewport={{ once: true, amount: 0.1 }}
							variants={areaAnimateRL}
							whileHover={{
								scale: 1.1,
								opacity: 0.8,
								transition: { duration: 0.5 },
							}}
						>
							<img className='rounded-t-xl' src={pos} />
						</motion.div>

					</div>
					<div className='w-full md:w-11/12 mx-auto mt-10 md:mt-20 px-2 md:px-20 md:flex'>
						<motion.div
							className="w-full md:w-1/2 mt-10 md:mt-0"
							initial={"offscreen"}
							whileInView={"onscreen"}
							viewport={{ once: true, amount: 0.1 }}
							variants={areaAnimateLR}
							whileHover={{
								scale: 1.1,
								opacity: 0.8,
								transition: { duration: 0.5 },
							}}
						>
							<img className='rounded-xl' src={pos_stock} />
						</motion.div>
						<div className='w-full md:w-3/4 md:pl-10 grid content-center'>
							<motion.p
								className='text-black font-sans font-semibold text-3xl text-right'
								initial={"offscreen"}
								whileInView={"onscreen"}
								viewport={{ once: true, amount: 0.1 }}
								variants={areaAnimateRL}
							>
								{lang === "EN" ? 'Control de Inventario' : 'Inventory Control'}
							</motion.p>
							<motion.p
								className='mt-5 text-black font-sans text-xl font-extralight text-right'
								initial={"offscreen"}
								whileInView={"onscreen"}
								viewport={{ once: true, amount: 0.1 }}
								variants={areaAnimateRL}
							>
								{lang === "EN" ? 'Monitorea tu inventario en tiempo real y recibe alertas cuando los niveles de stock son bajos.' :
									'Monitor your inventory in real time and receive alerts when stock levels are low.'}
							</motion.p>
						</div>
					</div>
					<div className='w-full md:w-11/12 mx-auto mt-10 md:mt-20 px-2 md:px-20 md:flex'>
						<div className='w-full md:w-3/4 pr-5 grid content-center'>
							<motion.p
								className='text-black font-sans font-semibold text-3xl'
								initial={"offscreen"}
								whileInView={"onscreen"}
								viewport={{ once: true, amount: 0.1 }}
								variants={areaAnimateLR}
							>
								{lang === "EN" ? 'Reportes y Análisis' : 'Reporting and Analytics'}
							</motion.p>
							<motion.p
								className='mt-5 text-black font-sans font-extralight text-xl'
								initial={"offscreen"}
								whileInView={"onscreen"}
								viewport={{ once: true, amount: 0.1 }}
								variants={areaAnimateLR}
							>
								{lang === "EN" ? `Obtén informes detallados sobre ventas, tendencias y desempeño del personal para tomar decisiones informadas.` :
									`Get detailed reports on sales, trends, and staff performance to make informed decisions.`}
							</motion.p>
						</div>
						<motion.div
							className="w-full md:w-1/2 mt-10 md:mt-0"
							initial={"offscreen"}
							whileInView={"onscreen"}
							viewport={{ once: true, amount: 0.1 }}
							variants={areaAnimateRL}
							whileHover={{
								scale: 1.1,
								opacity: 0.8,
								transition: { duration: 0.5 },
							}}
						>
							<img className='rounded-t-xl' src={pos_reporting} />
						</motion.div>
					</div>
					<div className="w-10/12 mx-auto mt-10 pt-5">
						<motion.h2
							initial={"offscreen"}
							whileInView={"onscreen"}
							viewport={{ once: true, amount: 0.1 }}
							variants={areaAnimateS}
							className='text-center font-sans text-5xl text-black mt-10'
						>{lang === "EN" ? '¿Por Qué Elegir el Software de Punto de Venta POS de Mirx Software?' : `Why Choose Mirx Software's Point of Sale (POS) System?`}</motion.h2>
						<div className="md:w-11/12 w-full mx-auto mt-2 p-3">
							<motion.p
								className='mt-5 text-black font-sans font-extralight text-xl'
								initial={"offscreen"}
								whileInView={"onscreen"}
								viewport={{ once: true, amount: 0.1 }}
								variants={areaAnimateRL}
							>{lang === "EN" ? `En Mirx Software, nuestro compromiso es proporcionar un sistema de punto de venta (POS) que satisface completamente las necesidades de tu negocio. Entendemos que cada negocio es único, por lo que nuestro POS está diseñado para ser flexible y adaptable, asegurando que se ajuste perfectamente a tus requerimientos específicos. Nos enfocamos en ofrecer una solución integral que no solo optimiza tus operaciones diarias, sino que también mejora la experiencia de tus clientes.` :
								`At Mirx Software, our commitment is to provide a point of sale (POS) system that fully meets the needs of your business. We understand that every business is unique, which is why our POS is designed to be flexible and adaptable, ensuring it fits perfectly with your specific requirements. We focus on offering a comprehensive solution that not only optimizes your daily operations but also enhances your customers' experience.`}</motion.p>
							<motion.p
								className='mt-5 text-black font-sans font-extralight text-xl'
								initial={"offscreen"}
								whileInView={"onscreen"}
								viewport={{ once: true, amount: 0.1 }}
								variants={areaAnimateRL}
							>{lang === "EN" ? `A diferencia de muchos otros proveedores, Mirx Software no impone costos mensuales de mantenimiento para el sistema de Punto de Venta (POS). Creemos que una vez que inviertes en nuestro software, no deberías preocuparte por cargos adicionales que afecten tu presupuesto. Nuestra política de costos transparentes y sin sorpresas te permite planificar mejor y maximizar tus recursos.` :
								`Unlike many other providers, Mirx Software does not impose monthly maintenance fees for the Point of Sale (POS) system. We believe that once you invest in our software, you shouldn’t worry about additional charges affecting your budget. Our transparent and surprise-free cost policy allows you to better plan and maximize your resources.`}</motion.p>
						</div>
					</div>
					<div className='w-full md:w-3/4 mx-auto bg-white rounded-xl md:px-20 mt-10 items-center'>
						<ReactPlayer 
							className="mx-auto"
							url='https://www.youtube.com/watch?v=8bs4UYuGj8w' 
						/>
					</div>
					<motion.h2
						initial={"offscreen"}
						whileInView={"onscreen"}
						viewport={{ once: true, amount: 0.1 }}
						variants={areaAnimateS}
						className='text-center font-sans text-5xl text-black mt-28'
					>{lang === "EN" ? 'Nuestros planes comerciales' : 'Our commercial plans'}</motion.h2>

					<motion.div
						className="w-full md:w-11/12 mx-auto mt-5 flex"
						initial={"offscreen"}
						whileInView={"onscreen"}
						viewport={{ once: true, amount: 0.1 }}
						variants={areaAnimateS}
					>
						<motion.div className="md:w-1/3 w-full mx-auto mt-10 md:mt-20 p-3 md:p-10 shadow-lg bg-gradient-to-r from-purple-700 to-fuchsia-700 rounded-xl"
							initial={"offscreen"}
							whileInView={"onscreen"}
							viewport={{ once: true, amount: 0.1 }}
							variants={areaAnimateLR}
							whileHover={{
								//scale: 1.1,
								//transition: { duration: 1 },
							}}
						>
							<div className='border-b border-fuchsia-600'>
								<p className='text-white text-2xl uppercase'>{lang === "EN" ? `Mensual` : `Monthly`}</p>
							</div>
							<p className='text-white text-4xl mt-10 mb-10 text-center'>$19 <span className='text-sm'>USD</span></p>
						</motion.div>
					</motion.div>
				</div>
			</main>
		</Layout>
	)
}

export default Pos