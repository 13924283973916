import { Link } from "react-router-dom";
import Layout from "../../components/admin/Layout";
//import Login from "./Login";

const HomeAdmin = () => {
	return (
		<Layout
			pagina="Inicio"
		>
			<div className="w-full h-screen bg-slate-200 p-10">
				<h1 className="mb-5 text-gray-500 text-3xl text-center">Administración del Sistema</h1>
				<div className="flex">
				</div>
			</div>
		</Layout>
	)
}

export default HomeAdmin