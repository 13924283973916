import { useContext } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import ContextStates from '../context/ContextStates'
import 'swiper/css/bundle';
import cmms from '../assets/viewequipo1.png'
import ecommerce from '../assets/ecommerce.jpg'
import pos from '../assets/punto-de-venta-inalambrico-1-1024x683.webp'

const Slider = () => {
    const { lang } = useContext(ContextStates);
    return (
        <Swiper
            spaceBetween={50}
            slidesPerView={1}
            observer = {true}
            centeredSlides={true}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            modules={[Autoplay, Pagination, Navigation]}
            
            speed={500}
            loop={true}
            touchRatio={1.5}
            effect={"fade"}
            className="mySwiper mt-10"
        >
            <SwiperSlide key="1">
                
                <div className='w-full h-screen md:h-96 mx-auto p-1 md:p-20 shadow-lg bg-gradient-to-r from-fuchsia-500 to-pink-500'>
                    <div>
                        <p className='text-white text-4xl font-sans text-center mt-20 md:mt-5'>{lang==="EN" ? `En Mirx, nos especializamos en desarrollar soluciones de software empresarial de vanguardia diseñadas para impulsar la eficiencia operativa. Nuestro producto estrella es nuestro sistema CMMS (Computerized Maintenance Management System) líder en la industria.`:
                        `At Mirx, we specialize in developing cutting-edge enterprise software solutions designed to drive operational efficiency. Our flagship product is our industry-leading Computerized Maintenance Management System (CMMS).`}</p>

                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide key="2">
                <div className='bg-black w-full h-full md:h-96 z-100 absolute opacity-50'>
                </div>
                <div className='w-full h-full md:h-96 z-50 absolute pt-10'>
                    <div className='w-full md:h-96'>
                        <blockquote>
                        <h3 className='text-white text-5xl font-sans font-bold text-center uppercase mt-10'>{lang==="ES" ? 'Sistema CMMS':'CMMS'}</h3>
                        </blockquote>
                        <p className='text-white text-4xl font-sans text-center mt-5 mx-10'>{lang==="EN" ? `Nuestro software CMMS ofrece una solución completa para la gestión y mantenimiento de sus activos.`:
                        `Our CMMS software offers a complete solution for managing and maintaining your assets.`}</p>

                    </div>
                </div>
                <img className='h-screen md:h-96 w-full object-cover' src={cmms} alt='CMMS'/>
            </SwiperSlide>
            <SwiperSlide key="3">
                <div className='bg-black w-full h-full md:h-96 z-100 absolute opacity-50'>
                </div>
                <div className='w-full md:h-96 z-50 absolute pt-10'>
                    <div className='w-full md:h-96'>
                        <blockquote>
                        <h3 className='text-white text-5xl font-sans font-bold text-center uppercase mt-10'>E-Commerce</h3>
                        </blockquote>
                        <p className='text-white text-4xl font-sans text-center mt-5 mx-10'>{lang==="EN" ? `¡Transforma tu negocio con nuestro software e-commerce de última generación!`:
                        `Transform your business with our cutting-edge e-commerce software!`}</p>
                    </div>
                </div>
                <img className='h-screen md:h-96 w-full object-cover' src={ecommerce} alt='CMMS'/>
            </SwiperSlide>
            <SwiperSlide key="4">
                <div className='bg-black w-full h-full md:h-96 z-100 absolute opacity-50'>
                </div>
                <div className='w-full h-full md:h-96 z-50 absolute pt-10'>
                    <div className='w-full md:h-96'>
                        <blockquote>
                        <h3 className='text-white text-5xl font-sans font-bold text-center uppercase mt-10'>{lang==="EN" ? 'Sistema de Punto de Venta (POS)':'Point of Sale (POS) System'}</h3>
                        </blockquote>
                        <p className='text-white text-4xl font-sans text-center mt-5 mx-10'>{lang==="EN" ? `Optimiza tus ventas, controla tu inventario y haz crecer tu negocio con nuestro POS de última generación.Nuestro software CMMS ofrece una solución completa para la gestión y mantenimiento de sus activos.`:
                        `Optimize your sales, control your inventory, and grow your business with our next-generation POS.`}</p>

                    </div>
                </div>
                <img className='h-screen md:h-96 w-full object-cover' src={pos} alt='POS'/>
            </SwiperSlide>
        </Swiper>
  );
}

export default Slider